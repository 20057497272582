<template>
  <div class="page">
    <div class="page__title">
      {{ getModuleConfig("card_list_page.form_title") }}
    </div>
    <div
      class="page__desc"
      v-html="getModuleConfig('card_list_page.form_desc')"
    ></div>
    <div
      class="page__content"
      v-if="getModuleConfig('card_list_page.content')"
      v-html="getModuleConfig('exchange_records_page.content')"
    ></div>
    <div class="reward-card-list">
      <template v-if="cardList.length > 0">
        <router-link
          :to="{ name: 'LiffProjAbbottAndRewardsCardDetail', params: { orgCode: $route.params.orgCode, id: item.id } }"
          class="reward-card-list__item"
          v-for="item in cardList"
          :key="item.id"
        >
          <div>
            <div class="reward-card-list__image">
              <img :src="item.img_url || './product-image.png'" />
            </div>
            <div class="reward-card-list__stamp">
              <i
                v-for="i in item.threshold"
                :key="i"
                class="fa fa-star"
                :class="item.point >= i && 'active'"
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div class="reward-card-list__content">
            <div class="reward-card-list__title">{{ item.name }}</div>
            <div class="reward-card-list__date">開始日期{{ formatDate(item.start_at) }}</div>
            <div class="reward-card-list__date">到期日期{{ formatDate(item.end_at) }}</div>
          </div>
        </router-link>
      </template>
      <template v-else>
        <div class="reward-card-list__item">
          <div class="reward-card-list__content">
            <div class="reward-card-list__title">您現在暫時沒有集點卡</div>
          </div>
        </div>
      </template>
      <!-- <PointPagination v-model="page" :totalPage="totalPage"></PointPagination> -->
    </div>
  </div>
</template>

<script>
// import PointPagination from "@/pages/Liff/Point/PointPagination";
import RewardCardMixin from "@/mixins/liff/rewards-card";
import abbottAndApi from "@/apis/liff/v2/proj/abbott-and";
import moment from "moment";

export default {
  mixins: [RewardCardMixin],
  components: {
    // PointPagination,
  },

  data() {
    return {
      cardList: [],
      // page: 1,
      // totalPage: 10,
    };
  },

  mounted() {
    this.fetchCardList();
  },

  watch: {
    // page() {
    //   this.fetchCardList();
    // },
  },

  methods: {
    formatDate(date) {
      return moment(date).format("YYYY/MM/DD");
    },
    async fetchCardList() {
      try {
        const { data } = await abbottAndApi.getLoyaltyList(this.$route.params.orgCode)
        this.cardList = data.data
      } catch (error) {
        console.error(error)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/shared/components/_fields.scss";
@import "../../../../../../node_modules/font-awesome/css/font-awesome.min.css";

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__content {
    margin: 10px 20px;
  }
}

.reward-card-list {
  &__item {
    display: flex;
    background: white;
    border: 1px solid #e5e5ea;
    margin-bottom: 12px;
    position: relative;
    overflow: hidden;
    padding: 12px;
    border-radius: 5px;
  }

  &__image {
    max-width: 122px;
    max-height: 122px;
    display: flex;
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__stamp {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    i {
      color: var(--gray);
      margin: 3px;

      &.active {
        color: var(--s-primary);
      }
    }
  }

  &__content {
    width: 100%;
    padding-left: 12px;
  }

  &__date {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #979797;
    margin-bottom: 8px;
    font-family: "PingFang TC";
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #2c2c2e;
    margin-bottom: 8px;
  }


}
</style>

<style scoped>
.reward-card-list__item {
  box-shadow: rgb(from var(--s-primary) r g b / 0.5) 0 2px 8px 0;
}
</style>
